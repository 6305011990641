import React from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import gasImg from '../../assets/icons/gas.png';
import { formatDate } from "../../utils/formatting";




interface GasBoilerCardProps extends RouteComponentProps<any> {
  GASPropsData:any
}
const GasBoilerCard: React.FC<GasBoilerCardProps> = ({GASPropsData}) => {
    const { t } = useTranslation();
    return (
      <div className=" relative border pb-16 border-grey bg-grey rounded-lg cust-card mt-4">
        <div className="px-4 py-2 border-b border-grey  bg-teal rounded-t-lg">
          <div className={window.screen.width>768? "flex flex-row d-flex bd-highlight items-center": "flex"}>
            <img src={gasImg} className=" text-2xl mr-6 sm:mr-4 bd-highlight logo logoLeft" alt="" />
            <h2 className="my-0 text-white bd-highlight ml-left">{t("GasBoiler")}

            </h2>
           </div>
        </div>  
         {/* Binding data here */}
         <div className="px-4 topStyle">
          <div className="pr-4 mt-2"><b>{t("Due_Date")}</b></div>
          <div className="cardEmail">{formatDate(GASPropsData?.visitDue || "-")}</div>
        </div>
        <div className="px-4 topStyle">
          <div className="pr-4 mt-2"><b>{t("Contractor_CP114")}</b></div>
          <div className="cardEmail">{GASPropsData?.contractor?.contractorName || "-"}</div>
        </div>
        <div className="px-4 topStyle">
          <div className="pr-4 mt-2"><b>{t("Contractor_Contact")}</b></div>
          <div className="cardEmail">{GASPropsData?.contractor?.contractorNumber || "-"}</div>
        </div>
        <div className="px-4 topStyle">
          <div className="pr-4 mt-2"><b>{t("Last_Visit")}</b></div>
          <div className="cardEmail">{formatDate(GASPropsData?.visitActual || "-")}</div>
        </div>
        <div className="px-4 topStyle">
          <div className="pr-4 mt-2"><b>{t("Visit_Outcome")}</b></div>
          <div className="cardEmail">{GASPropsData?.visitOutcome || "-"}</div>
        </div>
      </div>
    )
};
export default GasBoilerCard;